import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('@/components/Template/Empty'),
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('@/components/Modal/Signup'),
  },
  {
    path: '/files',
    name: 'Downloads',
    component: () => import('@/components/Modal/Downloads'),
  },
  {
    path: '/restorePassword',
    name: 'RestorePassword',
    component: () => import('@/components/Modal/RestorePassword'),
  },
  {
    path: '/restoreUsername',
    name: 'RestoreUsername',
    component: () => import('@/components/Modal/RestoreUsername'),
  },
  {
    path: '/changePassword',
    name: 'ChangePassword',
    component: () => import('@/components/Modal/ChangePassword'),
  },
  {
    path: '/changeEmail',
    name: 'ChangeEmail',
    component: () => import('@/components/Modal/ChangeEmail'),
  },
  {
    path: '/unbind',
    name: 'Unbind',
    component: () => import('@/components/Modal/Unbind'),
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('@/components/Modal/Contacts'),
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import('@/components/Modal/Support'),
  },
  {
    path: '/languages',
    name: 'Languages',
    component: () => import('@/components/Modal/LanguageForm'),
  },
  {
    path: '/vote',
    name: 'Vote',
    component: () => import('@/components/Modal/Vote'),
  },
  {
    path: '/media',
    name: 'Media',
    component: () => import('@/components/Modal/MediaForm'),
  },
  {
    path: '/activate/:md5',
    name: 'Activate',
    component: () => import('@/components/Modal/Activate'),
    props: route => ({
      md5: route.params.md5,
    }),
  },
  {
    path: '/server/:id?',
    name: 'Server',
    component: () => import('@/components/Modal/ServerForm'),
    props: route => ({
      id: parseInt(route.params.id),
    }),
  },
  {
    path: '/stats/:id?',
    name: 'Statistics',
    component: () => import('@/components/Modal/Statistics'),
    props: route => ({
      id: parseInt(route.params.id),
    }),
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('@/components/Modal/Shop'),
  },
  {
    path: '/shop2',
    name: 'Shop2',
    component: () => import('@/components/Modal/Shop2'),
  },
  {
    path: '/user_agreement',
    name: 'UserAgreement',
    component: () => import('@/components/Modal/License/UserAgreement'),
  },
  {
    path: '/privacy_agreement',
    name: 'PrivacyAgreement',
    component: () => import('@/components/Modal/License/PrivacyAgreement'),
  },
  {
    path: '/license_agreement',
    name: 'LicenseAgreement',
    component: () => import('@/components/Modal/License/LicenseAgreement'),
  },
  {
    path: '/g2a_agreement',
    name: 'G2AAgreement',
    component: () => import('@/components/Modal/License/G2AAgreement'),
  },
  {
    path: '/rules',
    name: 'Rules',
    component: () => import('@/components/Modal/License/Rules'),
  },
  {
    path: '/fail',
    name: 'Fail',
    component: () => import('@/components/Modal/Fail'),
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import('@/components/Modal/Success'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/components/Modal/Login'),
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/components/Modal/Account'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/components/Modal/Logout'),
  },
  {
    path: '/forum',
    name: 'Forum',
    beforeEnter() {
      window.open('https://forum.la2dream.com/', '_blank');
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Main' },
    component: () => import('@/components/Template/NotFound'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
