/**
 * Author: Vitalii P.
 * Site: https://get-web.site
 * Version: 0.1.0
 * https://github.com/get-web/insertmedia
 */

export function insertMedia(config) {
  // Pass in the objects to merge as arguments.
  // For a deep extend, set the first argument to `true`.
  const extend = function () {
    // Variables
    let extended = {};
    let deep = false;
    let i = 0;
    let length = arguments.length;

    // Check if a deep merge
    if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
      deep = arguments[0];
      i++;
    }

    // Merge the object into the extended object
    let merge = function (obj) {
      for (let prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          // If deep merge and property is an object, merge properties
          if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
            extended[prop] = extend(true, extended[prop], obj[prop]);
          } else {
            extended[prop] = obj[prop];
          }
        }
      }
    };

    // Loop through each object and conduct a merge
    for (; i < length; i++) {
      let obj = arguments[i];
      merge(obj);
    }

    return extended;
  };

  const cfg = extend(
    {
      delay: 0, // delay. default: 300
      immediately: true, // performing a delay true/false. Immediately or one at a time every "delay" seconds. default: true
      attr: 'data-insertmedia', // Processed attribute. default: data-insertmedia
    },
    config
  );

  // delay
  let counter = 1;
  const counterHandler = function () {
    if (cfg.immediately) return cfg.delay;
    return cfg.delay * counter++;
  };

  // youtube handler
  const youtubeHandler = function (el, options) {
    const settings = extend(
      {
        src: '',
        width: '300',
        height: '200',
        setting: '',
      },
      options
    );

    // Create thumbnail container with fallbacks
    const videoId = settings.src.split('/').pop();
    const thumbnailQualities = ['maxresdefault', 'sddefault', 'hqdefault', 'mqdefault', 'default'];

    const checkImage = async url => {
      try {
        const response = await fetch(url, { method: 'HEAD' });
        return response.ok;
      } catch {
        return false;
      }
    };

    const findFirstValidThumbnail = async () => {
      for (const quality of thumbnailQualities) {
        const url = `https://img.youtube.com/vi/${videoId}/${quality}.jpg`;
        if (await checkImage(url)) {
          return url;
        }
      }
      return `https://img.youtube.com/vi/${videoId}/${
        thumbnailQualities[thumbnailQualities.length - 1]
      }.jpg`;
    };

    // Create initial placeholder with loading spinner
    const container = document.createElement('div');
    container.className = 'media-placeholder';
    container.style.cssText = `width:${settings.width}px;height:inherit;position:relative;cursor:pointer;`;

    const loadingDiv = document.createElement('div');
    loadingDiv.style.cssText =
      'width:100%;height:100%;background:#000;display:flex;align-items:center;justify-content:center;';

    const spinner = document.createElement('div');
    spinner.style.cssText =
      'width:32px;height:32px;border:3px solid #fff;border-top-color:transparent;border-radius:50%;animation:spin 1s linear infinite;';

    loadingDiv.appendChild(spinner);
    container.appendChild(loadingDiv);
    el.innerHTML = '';
    el.appendChild(container);

    // Add loading animation style if not already present
    if (!document.querySelector('#thumbnail-loader-style')) {
      const style = document.createElement('style');
      style.id = 'thumbnail-loader-style';
      style.textContent =
        '@keyframes spin{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}';
      document.head.appendChild(style);
    }

    // Find and set the best thumbnail
    findFirstValidThumbnail().then(url => {
      const img = document.createElement('img');
      img.src = url;
      img.style.cssText = 'width:100%;height:100%;object-fit:cover;';

      const playButton = document.createElement('div');
      playButton.className = 'play-button';
      playButton.style.cssText =
        'position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);' +
        'width:68px;height:48px;background-color:#212121;border-radius:8px;' +
        'display:flex;align-items:center;justify-content:center;';
      playButton.innerHTML =
        '<svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">' +
        '<path d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 ' +
        'C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,' +
        '5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,' +
        '34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path>' +
        '<path d="M 45,24 27,14 27,34" fill="#fff"></path></svg>';

      container.innerHTML = '';
      container.appendChild(img);
      container.appendChild(playButton);
    });

    // Add click handler
    el.querySelector('.media-placeholder').addEventListener('click', () => {
      // Replace thumbnail with iframe
      el.innerHTML = `<iframe width="${settings.width}" height="${settings.height}" src="${settings.src}?${settings.setting}&autoplay=1" title="stream" allow="autoplay; fullscreen" style="border:none;" ></iframe>`;
    });
  };

  // twitch handler
  const twitchHandler = function (el, options) {
    const settings = extend(
      {
        src: '',
        width: '300',
        height: '200',
        setting: '',
      },
      options
    );

    // Create placeholder with Twitch logo
    el.innerHTML = `
      <div class="media-placeholder" style="width:${settings.width}px;height:inherit;position:relative;cursor:pointer;background-color:#6441a5;display:flex;align-items:center;justify-content:center;">
        <div class="play-button" style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);display:flex;flex-direction:column;align-items:center;color:white;">
          <svg width="48" height="48" viewBox="0 0 48 48" fill="white">
            <path d="M12,32l2.5-2.5l8,8l-2.5,2.5L12,32z M27.5,37.5l8-8l2.5,2.5l-8,8L27.5,37.5z M40,12v16H8V12H40z M36,16H12v8h24V16z"/>
          </svg>
          <span style="margin-top:8px;font-family:sans-serif;">Click to load Twitch stream</span>
        </div>
      </div>`;

    // Add click handler
    el.querySelector('.media-placeholder').addEventListener('click', () => {
      // Replace placeholder with iframe
      el.innerHTML = `<iframe width="${settings.width}" height="${settings.height}" src="${settings.src}&${settings.setting}" title="stream" allow="fullscreen" style="border:none;" ></iframe>`;
    });
  };

  // trovo handler
  const trovoHandler = function (el, options) {
    const settings = extend(
      {
        src: '',
        width: '300',
        height: '200',
        setting: '',
      },
      options
    );

    // Create placeholder with Trovo logo
    el.innerHTML = `
      <div class="media-placeholder" style="width:${settings.width}px;height:inherit;position:relative;cursor:pointer;background-color:#1faf66;display:flex;align-items:center;justify-content:center;">
        <div class="play-button" style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);display:flex;flex-direction:column;align-items:center;color:white;">
          <svg width="48" height="48" viewBox="0 0 48 48" fill="white">
            <path d="M12,32l2.5-2.5l8,8l-2.5,2.5L12,32z M27.5,37.5l8-8l2.5,2.5l-8,8L27.5,37.5z M40,12v16H8V12H40z M36,16H12v8h24V16z"/>
          </svg>
          <span style="margin-top:8px;font-family:sans-serif;">Click to load Trovo stream</span>
        </div>
      </div>`;

    // Add click handler
    el.querySelector('.media-placeholder').addEventListener('click', function () {
      // Replace placeholder with iframe
      el.innerHTML = `<iframe width="${settings.width}" height="${settings.height}" src="${settings.src}&${settings.setting}" title="stream" allow="fullscreen" style="border:none;" ></iframe>`;
    });
  };

  // images handler
  const imagesHandler = function (el, options) {
    const settings = extend(
      {
        src: '',
        width: '300',
        height: '200',
        setting: '',
      },
      options
    );
    el.innerHTML = `<img width="${settings.width}" height="${settings.height}" alt="image" src="${settings.src}">`;
  };

  // html5 handler
  const html5Handler = function (el, options) {
    const settings = extend(
      {
        src: '',
        width: '300',
        height: '200',
        setting: '',
      },
      options
    );
    el.innerHTML = `<video src="${settings.src}" ${settings.setting}></video>`;
  };

  // elements handler
  document.querySelectorAll(`[${cfg.attr}]`).forEach(function (el) {
    setTimeout(() => {
      const options = JSON.parse(`${el.getAttribute(cfg.attr)}`);
      if (!options.type && !options.src) return;
      if (options.type === 'youtube') youtubeHandler(el, options);
      if (options.type === 'twitch') twitchHandler(el, options);
      if (options.type === 'trovo') trovoHandler(el, options);
      if (options.type === 'img') imagesHandler(el, options);
      if (options.type === 'html5') html5Handler(el, options);
    }, counterHandler());
  });
}
