<template>
  <div class="section" data-section="servers">
    <div class="container">
      <div class="servers">
        <template v-if="!isLoading">
          <ServerItem
            v-for="(server, index) in get_servers"
            :key="server.id || index"
            :server="server"
          ></ServerItem>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ServerItem from '@/components/Body/Servers/ServerItem';

export default {
  name: 'Servers',
  components: { ServerItem },
  data() {
    return {
      loadingState: true,
    };
  },
  computed: {
    isLoading: {
      get() {
        return this.loadingState;
      },
      set(value) {
        this.loadingState = value;
      },
    },
    get_servers() {
      return this.$store.getters.get_servers.slice(
        0,
        parseInt(process.env.VUE_APP_MAX_SERVERS_DISPLAYED) || 6
      );
    },
  },
  async created() {
    try {
      await this.$store.dispatch('fetch_servers').then(() => {
        this.isLoading = false;
      });
    } catch (error) {
      console.error('Error fetching servers:', error);
    }
  },
};
</script>

<style scoped></style>
